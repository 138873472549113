@import "../../Variables.scss";

.chart-date-range {
  background: none;
  color: $black;
  border: 1px solid #cccccc;
  padding-top: .4rem;
  padding-bottom: .4rem;
  &:hover {
    color: $black;
  }
}

.date-ranges {
  display: block;
  .ranges {
    display: grid;
    width: 100%;
    grid-auto-flow: column;

    .btn {
      background: none;
      font-size: 12px;
      margin: 0 !important;
    }
  }
}
