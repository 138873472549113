@import "../../Variables.scss";

.Header {
  background-color: $navBarBg !important;

  @media (min-width: 992px) {
    padding-left: 0;
    padding-right: 0;
  }

  .container-fluid {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .navbar-brand {
    color: $navBarItemActive;
    &:focus {
      color: $navBarItemActive;
    }
    &:hover {
      color: $navBarItem;
    }
  }

  #home-link {
    padding: 0;

    img {
      height: 30px;
    }
  }

  .nav-link {
    color: $navBarItem !important;
    text-transform: uppercase;

    &:hover,
    &.active {
      color: $navBarItemActive !important;
    }
  }

  .dropdown {
    .dropdown-toggle {
      color: $navBarItemActive !important;

      @media (max-width: 991px) {
        padding-left: 0;
        padding-right: 0;
      }
    }
    .dropdown-menu {
      overflow: hidden;
      text-align: right;
      padding: .5rem;
      left: auto;
      right: -1rem;
      top: calc(100% + 0.5rem);
      background-color: $navBarBg;

      @media (max-width: 991px) {
        left: -1rem;
        text-align: left;
        padding-left: 1rem;
        .dropdown-item {
          padding-left: 0;
          padding-right: 0;
        }
      }

      .dropdown-item {
        color: $navBarItemActive;
        &:hover {
          background-color: darken($navBarBg, .20);
        }
      }
      .dropdown-divider {
        border-width: 3px;
        border-color: rgba($navBarItemActive, .50);
      }
      .dropdown-menu-item {
        color: $navBarItem !important;
        &:hover {
          color: $navBarItemActive !important;
          background-color: $navBarBg;
        }
      }
    }
  }
}