@import "../../Variables.scss";

// edit from guest.scss

.Job-assign {
    .card-body {
        background: white;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.28);
        width: 600px;

        & > img {
            height: 65px;
            margin: 0 auto;
            display: block;
        }
        & > h2 {
            text-align: center;
            color: $main-color;
            font-size: 16px;
            margin-top: 0.5rem;
            line-height: 1;
            margin-bottom: 45px;   
        }
    }

    h1 {
        text-align: center;
        color: $main-color;
        font-size: 18px;
        font-weight: normal;
        margin-top: 0.5rem;
        line-height: 1;
    }

    .padded {
        margin-top: 25px;
        margin-bottom: 45px;
    }
}