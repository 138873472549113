@import "../../Variables.scss";

#clients-table {
  table-layout: auto;

  .admin,
  .studio {
    word-wrap: break-word;
    @include media("<=mb15") {
      width: 300px;
      max-width: 300px;
    }
    @include media("<=mb13") {
      width: 250px;
      max-width: 250px;
    }
  }

  th.active {
    width: 110px;
  }

  th.complete {
    width: 140px;
  }
}
