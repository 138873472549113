@import "~include-media/dist/_include-media.scss";

// Colors
$main-color: #012169;
$main-color-light: rgba($main-color, 0.7);
$main-color-lighter: rgba($main-color, 0.4);
$white: #FFFFFF;
$black: #000000;
$gray: #888B8D;
$yellow: #CABA00;
$gray-light: rgba($gray, 0.4);

$navBarBg: $main-color-light;
$navBarItemActive: $white;
$navBarItem: $gray;
$modalHeaderBg: #E3E9FF;
$error: #d02434;
$success: #7795FF;
$primary: $main-color;
$borderOnInputFocus: #E3E9FF;
$tableHeaderBg: $white;

$blue-gradient: linear-gradient(
  0deg,
  #012169 35.48%,
  #2A63AF 77.58%,
  #2D68B4 78.01%,
  #14377D 99.21%
);
$light-blue-gradient: linear-gradient(
  360deg,
  rgba(1, 33, 105, 0.75) 15.25%,
  rgba(1, 33, 105, 0.09) 78.23%
);
$white-gradient: linear-gradient(
  180deg,
  rgba(255, 255, 255, 0.74) 2.4%,
  rgba(221, 228, 242, 0.74) 96.93%
);
$gray-gradient: linear-gradient(
  180deg,
  #CFCFCF 7.78%,
  #FFFFFF 34.2%,
  #BABABA 96.22%
);



$breakpoints: (
  'phone': 480px,
  'tablet': 768px,
  'lg': 992px, // Bootstrap
  'mb13': 1280px,
  'mb15': 1440px,
  'fhd': 1920px
);

// Bootstrap customization
$body-bg: #F2F2F2;
$body-color: $white;
$enable-rounded: false;
$border-width: 0;

$theme-colors: (
  "primary": $primary,
  "secondary": $success
);

// Dimensions
$navBarHeight: 49px;
