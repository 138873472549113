@font-face {
  font-family: 'onek';
  src:  url('/assets/fonts/icons/onek.eot?wyfg1i');
  src:  url('/assets/fonts/icons/onek.eot?wyfg1i#iefix') format('embedded-opentype'),
    url('/assets/fonts/icons/onek.ttf?wyfg1i') format('truetype'),
    url('/assets/fonts/icons/onek.woff?wyfg1i') format('woff'),
    url('/assets/fonts/icons/onek.svg?wyfg1i#onek') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="onek-"], [class*=" onek-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'onek' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.onek-caret-down:before {
  content: "\e900";
}
.onek-caret-up:before {
  content: "\e901";
}
.onek-projects:before {
  content: "\e909";
}
.onek-status:before {
  content: "\e907";
}
.onek-active-requests:before {
  content: "\e905";
}
.onek-complete-requests:before {
  content: "\e908";
}
.onek-created:before {
  content: "\e902";
}
.onek-update:before {
  content: "\e903";
}
.onek-client:before {
  content: "\e904";
}
.onek-active-projects:before {
  content: "\e90a";
}
.onek-complete-projects:before {
  content: "\e911";
}
.onek-request:before {
  content: "\e906";
}
.onek-service:before {
  content: "\e912";
}
.onek-started:before {
  content: "\e90b";
}
.onek-delivered:before {
  content: "\e90c";
}
.onek-source:before {
  content: "\e90d";
}
.onek-target:before {
  content: "\e90e";
}
.onek-add-info:before {
  content: "\e913";
}
.onek-close:before {
  content: "\e90f";
}
.onek-start:before {
  content: "\e910";
}
.onek-new:before {
  content: "\e914";
}
.onek-go:before {
  content: "\e915";
}
.onek-statistics:before {
  content: "\e916";
}
.onek-files:before {
  content: "\e917";
}
.onek-drag-drop:before {
  content: "\e918";
}
.onek-delete:before {
  content: "\e919";
}
.onek-size:before {
  content: "\e91a";
}
