#projects-table {
  table-layout: auto;

  th.status {
    width: 130px;
  }

  th.active {
    width: 110px;
  }

  th.complete {
    width: 140px;
  }

  th.datetime {
    width: 190px;
  }
}
