@import "../../Variables.scss";

.detail-proj-label {
  margin-right: 0.5rem !important;
}

#archived-request-table {
  table-layout: auto;

  th.lr {
    width: 5rem;
  }

  th.service {
    width: 9rem;
  }

  th.datetime {
    width: 10rem;
  }

  th.source-lang {
    width: 9rem;
  }

  th.price {
    width: 6rem;
  }

  th.files {
    width: 6rem;
  }
}

/*
  #archived-request-table {
    table-layout: auto;

    th.lr {
      width: 80px;
    }

    th.service {
      width: 10px;
    }

    th.datetime {
      width: 160px;
    }

    th.source-lang {
      width: 110px;
    }

    th.price {
      width: 80px;
    }
  }
/**/