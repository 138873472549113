#contact-list {
    display: grid;
    justify-items: stretch;
    align-content: stretch;
    align-items: stretch;
}

#contact-list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: stretch;
    flex-wrap: nowrap;
}

.centered {
    flex: auto;
    align-items: center;
}

#checkbox-column {
    max-width: 38px;
}

p {
    margin-top: 0.5em;
}

#checkbox {
    margin-top: 0.5em;
}