@import "../../Variables.scss";

.lp-header-col {
  font-weight: normal;
  display: flex;
  align-items: flex-end;
  line-height: 1.1;
  i {
    font-size: 36px;
    margin-right: 0.5rem;

    @include media("<fhd") {
      font-size: 24px;
    }
  }
  .sort-carets {
    margin-left: 0.5rem;
    i {
      font-size: 12px;
      margin-right: 0;
      &:not(.active) {
        color: $gray;
      }
    }
  }
}

.small .lp-header-col > i {
  font-size: 24px;
}
