@import "../../Variables.scss";

.Guest-hero {
  margin: 50px 0 65px;

  @media (max-width: 1280px) {
    margin: 0 0 16px;
  }

  .announcement {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($body-color, .10);
  }
  .logo-container {
    background-color: $body-color;
    text-align: center;

    @media (max-width: 1280px) {
      img {
        max-height: 120px;
      }
    }
  }
}

.Guest-form {
  .card-body {
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.28);
    width: 650px;

    & > img {
      height: 90px;
      margin: 0 auto;
      display: block;
    }
    & > h1 {
      text-align: center;
      color: $main-color;
      font-size: 22px;
      margin-top: 0.5rem;
      line-height: 1;
    }

    form {
      padding: 40px;

      .form-group:nth-of-type(2) .form-control {
        &,
        &:focus,
        &:hover,
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus {
          background-color: $main-color-light;
        }
      }

      h1 {
        color: $main-color;
        font-size: 31px;
        font-weight: normal;
      }

      button[type="submit"] {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        font-size: 20px;
        text-transform: uppercase;
        font-weight: normal;
        width: 100%;
        margin-top: 1.3rem;
        background: $main-color !important;
        color: $white !important;
      }
    }

    a {
      font-weight: normal;
      color: $gray;
      text-decoration: none;
      text-transform: uppercase;
      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }
}
