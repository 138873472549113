@import "./Variables.scss";
@import "~bootstrap/scss/bootstrap";
@import "./icons.css";

@font-face {
  font-family: Prometo;
  src: url("/assets/fonts/Prometo.woff") format('woff'),
       url("/assets/fonts/Prometo.woff2") format('woff2'),
       url("/assets/fonts/Prometo.ttf") format('ttf'),
       url("/assets/fonts/Prometo.eot") format('eot');
  font-weight: 400;
  font-style: normal;
}

body {
  font-family: Prometo, system-ui, sans-serif;
}

.App {
  padding-top: $navBarHeight;
  height: 100vh;
}

* {
  outline: 0 !important;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.section-header {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  column-gap: 1rem;
  align-items: flex-end;
  border-bottom: 1px solid $white;
  margin-bottom: 0.8rem;

  &.has-logo {
    grid-template-columns: auto 1fr;
  }

  .logo {
    height: 80px;
    margin-bottom: 0.7rem;
  }

  h1 {
    font-size: 32px;
    font-weight: bolder;
    line-height: 1.5;
    margin: 0;

    @include media("<=mb13") {
      font-size: 24px;
    }
  }

  .section-subheader {
    display: grid;
    grid-auto-flow: column;
    column-gap: 2rem;
    align-items: end;
    padding-bottom: .25rem;

    &.multi-line {
      grid-auto-flow: row;
    }
  }
}

.hero {
  position: relative;
  align-items: flex-end;
  padding: 1rem;
  height: 175px;

  &:before,
  .banner {
    content: "";
    position: absolute;
    top: 0;
    left: 15px;
    right: 15px;
    bottom: 0;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;
  }
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 15px;
    right: 15px;
    bottom: 0;
    background: $light-blue-gradient;
    z-index: -1;
  }

  &:before {
    background-image: url('/assets/images/project_bg.jpg');
  }
  h1 {
    font-weight: normal;

    .icon-only {
      display: inline-flex;
      width: auto;
      margin-left: .25rem;
      vertical-align: top;
      margin-top: 0.6rem;
      font-size: .5em;
      color: darken($white, 15);
      &:hover {
        color: $white;
      }
    }
  }
}

.ResourceTable {
  .table thead th {
    vertical-align: bottom;
  }
  .pagination {
    .page-link {
      color: $navBarItem;
      background-color: $black;
      &:focus {
        box-shadow: none;
      }
    }
    .active {
      .page-link {
        color: $navBarItemActive;
      }
    }
  }
}

i.big {
  font-size: 24px;
  margin-right: .5rem;
}

button.link {
  border: 0;
  background: none;
  display: inline;
  color: inherit;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}

.tabbed-modal {
  max-width: 900px;

  @include media("<=lg") {
    max-width: 700px;
  }

  @include media("<=tablet") {
    max-width: calc(100% - 2rem);
  }

  .modal-content {
    height: 600px;

    .modal-body {
      padding: 0;
      overflow: hidden;
      > .row {
        margin: 0;
        height: 100%;
        overflow: hidden;
        .tab-container {
          padding: 0 4rem 2rem;
          overflow-y: auto;
          width: 80%;
          max-height: 100%;

          .tab-content {
            height: 100%;
            display: flex;
            overflow-y: auto;

            .tab-pane {
              flex: 1;
              width: 100%;
              padding-right: 1rem;

              h1 {
                font-size: 1.3rem;
              }

              h2 {
                font-size: 1.2rem;
              }

              form {
                display: grid;
                grid-auto-flow: row;
                height: 100%;
                grid-template-rows: 1fr auto;

                .toast {
                  align-self: flex-start;
                }

                &.has-error {
                  grid-template-rows: auto 1fr auto;
                }
                .fields {
                  overflow: auto;
                }
                .actions {
                  padding-top: 1rem;
                }
              }
            }
          }
        }
        .tabs {
          display: flex;
          align-items: center;
          padding: 0;
          width: 20%;
          margin-top: -116px;

          @include media("<=lg") {
            margin-top: 0;
          }

          .nav {
            width: 100%;

            .nav-item {
              @include media(">lg") {
                width: 100%;
                .nav-link {
                  &:before {
                    content: "> "
                  }
                }
              }
              .nav-link {
                color: $gray;
                text-transform: uppercase;
                text-decoration: underline;
                &.active,
                &:hover {
                  color: $white;
                  text-decoration: none;
                }
                &.disabled {
                  text-decoration: none;
                }
              }
            }
          }
        }

        @include media("<=lg") {
          flex-direction: column;
          margin-right: 0;
          .tab-container,
          .tabs {
            width: 100%;
          }
          .tab-container {
            padding: 0 2rem 1rem;
            flex: 1;
          }
          .tabs {
            padding: 0 1rem 1rem;
            .nav-item {
              width: auto;
            }
          }
        }
      }
    }
  }
}

.icon-only {
  display: inline-block;
  width: 32px;
  height: 32px;
  color: $white;
  background: none;
  border: 0;
  padding: 0;
  transition: color 0.25s ease;
  &:hover {
    color: darken($white, 15);
  }
}

.uppercase {
  text-transform: uppercase;
}

.transform-none {
  text-transform: none !important;
}

.Statistics {
  .card {
    background: $white;

    .card-header,
    .card-body {
      background: none;
    }
    .card-header {
      align-self: flex-start;
      border-bottom: 2px solid $main-color;
      padding-bottom: .25rem;
      padding-right: .25rem;
    }
    .card-body {
      width: 100%;
    }
  }

  .badge {
    float: right;
    margin-top: -2px;
    border-radius: 10px;
    background: $gray;
    color: $white;
    font-size: 1.2rem;
    font-weight: normal;
  }

  .title-and-filters {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      display: block;
      margin-top: 1rem;
    }
    .filters {
      margin-left: 4rem;
      flex: 1;
      display: flex;
      justify-content: flex-end;

      .form-group {
        flex: 1;
        max-width: 300px;

        &:not(:first-child) {
          margin-left: 2rem;
        }

        .form-check {
          margin-top: 3rem;
          input {
            margin-top: 6px;
          }
          label {
            display: block;
            font-size: 20px;
          }
        }
      }
    }
  }

  .relative {
    position: relative;
    z-index: 0;
    #projects-count {
      position: absolute;
      z-index: -1;
      margin-bottom: 2rem;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .big {
        font-size: 4rem;
        line-height: 1;
        display: block;
      }
    }
  }

  .chart-container{
    position: relative;

    &.noData,
    &.loading {
      &:after {
        content: "No data available";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba($black, 0.3);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2rem;
        pointer-events: none;
      }
    }
    &.noData:after {
      content: "No data available";
    }
    &.loading:after {
      content: "Fetching data...";
    }
  }

  .table th {
    white-space: nowrap;
  }
  .table-responsive {
    &::-webkit-scrollbar{
      background-color: white;
      border-radius: 10px;
      width: 20px;
    }
    &::-webkit-scrollbar-thumb{
      background-color: grey;
      border-radius: 10px;
      border: 5px solid white;
    }
    &::-webkit-scrollbar-thumb:vertical{
      height: 20px!important;
      width: 20px;
    }
  }
}

// Bootstrap customization
.container,
.container-fluid {
  padding: 16px;

  @include media(">mb13") {
    padding: 60px;
  }
  @include media(">=fhd") {
    padding: 60px 100px;
  }
}

.table {
  thead {
    background-color: $tableHeaderBg;
    color: $main-color;
    font-size: 1rem;
    text-transform: uppercase;

    th {
      vertical-align: middle;
      &:focus {
        outline: 0;
      }
      &.size-cell {
        width: 70px;
      }
      &.action-cell {
        width: 50px;
      }
      &.progress-cell {
        width: 200px;
      }
      &.one-btn {
        width: 56px;
      }
      &.two-btn {
        width: 88px;
      }
    }
  }
  tbody {
    tr {
      background-color: $main-color !important;
      color: $white;

      &:hover,
      &:focus {
        background-color: $gray !important;
      }

      &.clickable {
        cursor: pointer;
      }

      td {
        padding: 0.4rem 0.75rem;
        vertical-align: middle;
        height: 44px;
        white-space: nowrap;
        font-size: 14px;
        text-transform: uppercase;

        &.status-cell {
          font-style: italic;
        }
      }
    }
  }
  &.small {
    th, td {
      font-size: 12px;
    }
    td {
      max-height: 44px;
      text-overflow: ellipsis;
      max-width: 270px;
      overflow: hidden;
    }
  }
  &.layout-fixed {
    table-layout: fixed;
  }
}

.modal-content {
  background: $blue-gradient;

  .modal-header {
    color: $white;
    text-transform: uppercase;
    padding-top: 3rem;
    padding-bottom: 2rem;
    position: relative;
    &:after {
      content:"";
      position: absolute;
      top: 0;
      left: 0;
      background-image: url('/assets/images/One-K-Logo_White.svg');
      background-size: auto 20px;
      background-repeat: no-repeat;
      background-position: 1rem 1rem;
      height: 3rem;
      width: 6rem;
      opacity: 0.2;
    }


    .modal-title {
      font-weight: normal;
      text-align: center;
      width: 100%;
    }

    button.close {
      position: absolute;
      right: 0;
      top: 0;
      width: 26px;
      line-height: 19px;
      padding: 0;
      padding-bottom: 3px;
      padding-top: 2px;
      margin: 1rem;
      color: $white;
      box-shadow: none;
      text-shadow: none;
      opacity: 1;
      border: 1px solid $white;
      font-weight: normal;
    }
  }
  .modal-body {
    display: flex;
    flex-direction: column;
    & > p {
      text-align: center;
    }

    & > .row {
      flex-grow: 1;
    }

    .container,
    .container-fluid {
      padding: 0;
    }

    .form-label {
      color: $white;
    }
    .form-control {
      background-color: rgba($white, 0.2);
      &::placeholder {
        color: $white;
      }
    }
  }
}

.modal-backdrop {
  z-index: 1050;
}

.btn {
  &:not(.btn-danger) {
    background: $gray-gradient;
    color: $main-color;
    transition: all 0.2s ease-in-out;

    &.btn-secondary {
      color: $main-color;
    }
    &.btn-link.as-link {
      color: inherit;
      text-transform: none;
      vertical-align: baseline;
      font-weight: normal;
      padding: 0;
      background: none !important;
      &:hover,
      &:focus {
        color: inherit;
        text-decoration: underline;
      }
    }
    &:not([disabled]) {
      &:hover, &:focus {
        box-shadow: none;
        color: $main-color;
        background: $white-gradient;
      }
    }
    &:disabled {
      color: $gray;
    }

    + .btn {
      margin-left: 1rem;
    }
  }
  i {
    font-size: 14px;
  }
}

.toast {
  background-color: $error;
  border: 0;
  max-width: none;
  flex-basis: auto;

  &.success {
    background-color: $success;
  }

  .toast-header {
    color: $black;
  }
}

.form-control {
  font-size: 20px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  height: auto;

  &,
  &:focus,
  &:hover {
    background-color: $main-color-lighter;
    color: $white;
  }

  &:focus {
    border-color: $borderOnInputFocus;
    box-shadow: 0 0 0 0.2rem rgba($borderOnInputFocus, 0.3)
  }
  &.is-invalid {
    border: 1px solid $error;
    background-image: none;
    &:focus {
      box-shadow: none;
      border-color: $error;
    }
  }
  &.react-datepicker-wrapper input {
    width: 100%;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: $white;
    box-shadow: none;
    transition: background-color 5000s ease-in-out 0s;
  }

  &::placeholder,
  &::-webkit-input-placeholder,
  &::-moz-placeholder,
  &:-ms-input-placeholder,
  &:-moz-placeholder {
    color: $white;
    text-transform: uppercase;
    text-align: center;
  }
}

.form-label {
  color: $gray;
  font-size: 12px;
  text-transform: uppercase;
}

.form-check-input {
  margin-top: 0.4rem;
}

.invalid-feedback {
  background-color: $error;
  color: $white;
  margin-top: 0;
  padding: 2px 4px;
}

label.form-control {
  background: none;
  color: white;
  padding: 0;
  margin-bottom: 2px;
  height: auto;
  &.is-invalid {
    border: 0;
  }
}

div.form-control {
  background: none;
  padding: 0;
  height: auto;
  &.is-invalid {
    padding: 0;
  }
}

.card {
  background: none;
  margin: 0 auto;
  color: $black;

  .card-header {
    color: $main-color;
    font-size: 31px;
    font-weight: normal;
  }

  .card-body {
    background: $white-gradient;
    padding: 24px;
    margin: 0 auto;

    @media (min-width: 768px) {
      padding: 40px;
    }
  }
}

.tooltip-inner {
  max-width: 220px;
}

// Toast customiziation
.Toastify__toast-container--top-right {
  top: 4.5rem !important;
}

.Toastify__toast--error {
  background: $error;
}

.Toastify__toast--success {
  background: $success;
}