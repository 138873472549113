@import "../../Variables.scss";

#background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: hidden;
  z-index: -1;

  .letter-group {
    display: grid;
    grid-template-columns: repeat(3, calc(100vw/18));
    grid-template-rows: repeat(3, calc(100vw/36));
    justify-items: center;

    @include media("<=mb15") {
      grid-template-columns: repeat(3, calc(100vw/15));
      grid-template-rows: repeat(3, calc(100vw/18));
    }
    @include media("<=lg") {
      grid-template-columns: repeat(3, calc(100vw/12));
      grid-template-rows: repeat(3, calc(100vw/12));
    }
    @include media("<=tablet") {
      grid-template-columns: repeat(3, calc(100vw/9));
      grid-template-rows: repeat(3, calc(100vw/6));
    }
    @include media("<=phone") {
      grid-template-columns: repeat(3, calc(100vw/3));
    }

    .letter {
      color: $gray-light;
      transition: all 0.2s ease-in-out;
      font-size: 23px;
      font-weight: 200;
      user-select: none;

      @include media("<=mb15") {
        font-size: 18px;
      }
      @include media("<=tablet") {
        font-size: 14px;
      }
    }
    &.highlightable.highlight {
      .letter {
        color: $gray;
        font-weight: bold;
        cursor: default;
      }
    }
  }
}
