@import '../../Variables.scss';

#archived-table {
  table-layout: auto;

  th.status {
    width: 8rem;
  }

  th.count {
    width: 5rem;
  };

  th.datetime {
    width: 10rem;
  }

  button.row-button {
    width: 100%;
    background: transparent;
    color: $white;
    text-transform: uppercase;
    font-size: 14px;
  }
}
  