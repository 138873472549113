@import "../../Variables.scss";

#requests-table {
  table-layout: auto;

  th.status {
    width: 144px;
  }

  th.datetime {
    width: 160px;
  }

  th.date {
    width: 160px;
  }

  th.number {
    width: 160px;
  }

  th.service {
    width: 190px;
  }

  th.source-lang {
    width: 110px;
  }

  th.price {
    width: 110px;
  }

  th.action {
    width: 140px;
  }
}

.react-bs-table-sizePerPage-dropdown .dropdown-menu {
  bottom: -10px;
  top: auto;
}

#lr-filters {
  position: relative;
  background: $main-color;
  padding: 1rem;
  z-index: 2;

  input.form-control {
    background: rgba($white, 0.2);
    border: 1px solid #CCC;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    padding-right: 0.75rem;
    height: 38px;

    &::placeholder {
      color: $white;
      text-transform: uppercase;
      text-align: left;
    }
  }

  .form-control {
    font-size: 14px;
  }

  .btn {
    margin-top: 30px;
    padding-top: 7px;
    padding-bottom: 7px;
    min-width: 38px;
  }
}
