@import "../../Variables.scss";

$outerCircle: 27px;
$innerCircle: 19px;
$barHeight: 5px;
$barWidth: 100px;

.NewRequest {
  .modal-header {
    @include media("<=mb15") {
      padding: 0.75rem;
    }
    .modal-title {
      @include media("<=mb15") {
        font-size: 1.1rem;
        line-height: 1.2;
      }
    }
  }
  .modal-content {
    height: 80vh;
    @include media("<=mb15") {
      height: 90vh;
    }
    @include media("<=mb13") {
      height: 100vh;
    }
  }
  .modal-footer {
    justify-content: space-between;

    @include media("<=phone") {
      align-items: flex-end;
    }

    #delete-btn {
      margin-right: 2rem;
      @include media("<=phone") {
        display: block;
        margin: 0.5rem 0 1rem;
      }
    }
  }

  h4 {
    font-size: 22px;
    text-align: center;
    margin-bottom: 2rem;
    padding: 0 1em;
    @include media("<=fhd") {
      font-size: 18px;
    }
    @include media("<=mb15") {
      font-size: 16px;
      margin-bottom: 1rem;
    }
  }

  #request-steps {
    position: relative;
    display: flex;
    flex-direction: row;
    width: $barWidth;
    justify-content: space-between;
    margin: 2rem auto 1rem;

    @include media("<=mb15") {
      margin: 0 auto 1rem;
      width: calc(#{$barWidth}/2);
    }

    &:before {
      content: "";
      position: absolute;
      width: calc(100% - 20px);
      margin: 0 10px;
      height: $barHeight;
      left: 0;
      top: 50%;
      margin-top: calc(#{$barHeight} * -0.5);
      background-color: $white;
      z-index: 0;
      @include media("<=mb15") {
        height: calc(#{$barHeight}/2);
        margin-top: calc(#{$barHeight}/2 * -0.5);
      }
    }

    .step {
      width: $outerCircle;
      height: $outerCircle;
      border-radius: 50%;
      background-color: $main-color;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
      border: 2px solid $white;

      @include media("<=mb15") {
        width: calc(#{$outerCircle}/2);
        height: calc(#{$outerCircle}/2);
      }

      &:after {
        content: "";
        width: $innerCircle;
        height: $innerCircle;
        border-radius: 50%;
        transition: all 0.25s ease;
        @include media("<=mb15") {
          width: calc(#{$innerCircle}/2);
          height: calc(#{$innerCircle}/2);
        }
      }

      &.active:after,
      &.done:after {
        background-color: $white;
      }
    }
  }
}
