@import '../../Variables.scss';

.RequestStatus {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100px;
  justify-content: space-between;

  &:before {
    content: "";
    position: absolute;
    width: calc(100% - 20px);
    margin: 0 10px;
    height: 3px;
    left: 0;
    top: 50%;
    margin-top: -1.5px;
    background-color: $white;
  }

  .status {
    width: 17px;
    height: 17px;
    border-radius: 50%;
    border: 1px solid $white;
    background-color: $main-color;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    &:after {
      content: "";
      width: 13px;
      height: 13px;
      border-radius: 50%;
    }

    &.done:after {
      background-color: $white;
    }

    &.in-progress:after {
      background-color: $yellow;
    }

    &.error:after {
      background-color: $error;
    }
  }
}

.table tr:hover,
.table tr:focus {
  .RequestStatus .status {
    background-color: $gray;
  }
}